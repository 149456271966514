import { Pagination } from "../../../../utils/pagination";

export interface ILoanRequest {
    loanRequestData: any | null;
    loanRequestDataPagination: Pagination
    error: string;
    loading: boolean;
    success: boolean;
    loanRequestDataDetailData: any | null;
    loanRequestDataDetailError: string;
    loanRequestDataDetaiLoading: boolean;
    loanRequestDataDetailSuccess: boolean;
    loanReassignedData: any | null;
    loanReassignedError: string;
    loanReassignedLoading: boolean;
    loanReassignedSuccess: boolean;

}
export interface ILoanRequestPayLoad {
    requestStatus: string,
    businessName: string,
    businessOwnerName: string,
    dateRequested: string,
    firstFcoUsername: string,
    secondFcoUsername: string,
    pmUsername: string,
    loanAmount: number,
    loanRequestReference: string
}
export enum LoanRequestStatus {
    AWAITING_ASSIGNMENTS = 'AWAITING_ASSIGNMENTS',
    AWAITING_FIRST_FCO_APPROVAL = 'AWAITING_FIRST_FCO_APPROVAL',
    AWAITING_SECOND_FCO_APPROVAL = 'AWAITING_SECOND_FCO_APPROVAL',
    AWAITING_PM_APPROVAL = 'AWAITING_PM_APPROVAL',
    AWAITING_FINAL_APPROVAL = 'AWAITING_FINAL_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH = "AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH",
    AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION = "AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION",
}
export const enum ReassignType {
    REASSIGN_FIRST_FCO = 'Reassign First FCO',
    REASSIGN_SECOND_FCO = 'Reassign Second FCO',
    REASSIGN_PM = 'Reassign Portfolio Manager',
}

export const enum OfficerType {
    FIRST_FCO = 'FIRST_FCO',
    SECOND_FCO = 'SECOND_FCO',
    PMO = 'PM',
}
export const loanRequestStatusObj = [

    {
        id: 1,
        status: 'AWAITING_ASSIGNMENTS',
        value: 'AWAITING ASSIGNMENTS'
    },
    {
        id: 2,
        status: 'AWAITING_FIRST_FCO_APPROVAL',
        value: 'AWAITING FIRST FCO APPROVAL'
    },
    {
        id: 3,
        status: 'AWAITING_SECOND_FCO_APPROVAL',
        value: 'AWAITING SECOND FCO APPROVAL'
    },
    {
        id: 4,
        status: 'AWAITING_PM_APPROVAL',
        value: 'AWAITING PM APPROVAL'
    },
    {
        id: 5,
        status: 'AWAITING_FINAL_APPROVAL',
        value: 'AWAITING FINAL APPROVAL'
    }, {
        id: 6,
        status: 'APPROVED',
        value: 'APPROVED'
    }, {
        id: 7,
        status: 'REJECTED',
        value: 'REJECTED'
    },
    {
        id: 8,
        status: 'AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH',
        value: 'AWAITING VERIFICATIONS AND AGREEMENTS DISPATCH'
    }, {
        id: 9,
        status: 'AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION',
        value: 'AWAITING VERIFICATIONS AND AGREEMENTS EXECUTION'
    },
]
export enum LoanRequestVerificationStatus {
    PENDING='PENDING',
    SENT_FOR_VERIFICATION='SENT_FOR_VERIFICATION',
    SUCCESSFUL='SUCCESSFUL',
    FAILED='FAILED',
    CANCELLED_DUE_TO_REASSIGNMENT='CANCELLED_DUE_TO_REASSIGNMENT',
    CANCELLED_DUE_TO_REJECTION='CANCELLED_DUE_TO_REJECTION',
    SKIPPED = "SKIPPED",
}

export enum LoanRequestVerificationAcceptanceStatus {
    ACCEPTED='ACCEPTED',
    REJECTED='REJECTED'
}

export type ResendVerificationType={
    verificationReference:string,
    reverificationReason:string,
}
export enum loanRequestTabEnum {
    AWAITING_SUBMISSION = 'AWAITING_SUBMISSION',
    AWAITING_OFFER = 'AWAITING_OFFER',
    PENDING_OFFER = 'PENDING_OFFER',
    // SUBMITTED_REQUESTS = 'SUBMITTED_REQUESTS',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    TERMINATED = 'TERMINATED'
}
export enum loanRequestTabEnumString {
    AWAITING_SUBMISSION = 'AWAITING SUBMISSION',
    SUBMITTED_REQUESTS = 'SUBMITTED REQUESTS',
    PENDING_OFFER = 'PENDING OFFER',
    AWAITING_OFFER = 'AWAITING OFFER',
    PENDING_APPROVAL = 'PENDING APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    TERMINATED = 'TERMINATED'
}
export const loanRequestStatusAPIPath: { [key: string]: string } = {
    AWAITING_SUBMISSION: 'awaiting-submission',
    SUBMITTED_REQUESTS: 'submitted',
    PENDING_OFFER: 'pending-offer-acceptance',
    AWAITING_OFFER: 'awaiting-offer',
    PENDING_APPROVAL: 'pending-approval',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    TERMINATED: 'terminated'
}
